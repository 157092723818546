.App {
  text-align: center;
}

.bitbank-logo {
  /* height: 40vmin; */
  width: 250px;
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  /* background-color: red; */
}

.add-network-button {
  width: 200px;
  height: 48px;
  background-color: #0675c9;
  color: white;
  border-radius: 12px;
  border: none;
  font-weight: 600;
}